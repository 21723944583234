<template>
  <div class="m-8">
    <v-row align="center" justify="center" class="button-print">
      <v-btn color="primary" @click="print"> IN PHIẾU THANH TOÁN </v-btn>
    </v-row>
    <v-row class="mt-0">
      <v-col cols="8">
        <p class="text-medium mb-0">CÔNG TY CỔ PHẦN BẢO ĐẠI ĐƯỜNG</p>
        <span>Phòng khám Chuyên khoa Y học cổ truyền</span>
        <p class="text-small mb-0">
          Địa chỉ: <span>Số 146, ngõ 136, Tây Sơn, Đống Đa, Hà Nội</span>
        </p>
        <p class="text-small mb-0">
          VPGD: <span>Số 148, ngõ 91, Trần Quang Diệu, Đống Đa, Hà Nội</span>
        </p>
        <p class="text-small mb-0">Hotline: <span>084.22.11.348 - 084.23.11.348</span></p>
      </v-col>
      <v-col class="flex">
        <div>
          <img src="../assets/logo/logo.png" alt="logo" class="w-15 h-12" />
        </div>
        <div>
          <p class="brand mt-1">BẢO ĐẠI ĐƯỜNG</p>
          <img
            class="qr-code mx-auto"
            src="https://res.cloudinary.com/dl2pj1j7w/image/upload/v1680756441/quanlyphongkham/baodaiduong/patient-prescription/4/oorv0yvnagicmpuwygux.png"
          />
        </div>
      </v-col>
    </v-row>
    <v-row align="center" justify="center" class="mt-2">
      <div class="text-h5 title">PHIẾU THANH TOÁN</div>
    </v-row>
    <v-row class="mt-4 mb-0 py-0">
      <v-col cols="8" class="py-0">
        <p class="text-h7">Họ và tên khách hàng: <span>xxxx</span></p>
      </v-col>
      <v-col cols="2" class="py-0">
        <p class="text-h7">Tuổi: <span>xxxx</span></p>
      </v-col>
      <v-col cols="2" class="py-0">
        <p class="text-h7">Giới tính: <span>xxxx</span></p>
      </v-col>
    </v-row>
    <p class="text-h7">Địa chỉ: <span>xxxx</span></p>
    <p class="text-h7">Số Điện thoại: <span>xxxx</span></p>
    <p class="text-h7">Triệu chứng: <span>xxxx</span></p>
    <p class="text-h7">Chẩn đoán: <span>xxxx</span></p>
    <p class="text-h7 mt-2">THÔNG TIN ĐƠN HÀNG</p>
    <div class="row">
      <div style="display: inline-block; padding-left: 10px">
        <p class="mt-2">-Thuốc: Liệu trình</p>
      </div>
      <div
        style="display: inline-block; padding-left: 10px; padding-right: 10px; width: 5%"
      >
        <v-text-field
          hide-details
          single-line
          class="shrink"
          dense
          variant="underlined"
        ></v-text-field>
      </div>
      <div class="column3" style="display: inline-block">
        <p class="mt-2">tuần bao gồm:</p>
      </div>
    </div>
    <v-row class="mt-5 mb-2">
      <v-data-table
        class="table-data custom-border-table table-no-stripe"
        no-data-text="Không có dữ liệu"
        :headers="headers"
        :items="items"
        disable-pagination
        hide-default-footer
      >
        <template v-slot:[`item.description`]="{ item }">
          <span>{{ item.description }}</span><br>
          <div class="flex mb-2">
            <p style="min-width: 90px">Cách dùng: </p>
            <span class="break-words print-show">{{ item.modelGuide }}</span>
            <v-textarea
              v-model="item.modelGuide"
              hide-details
              auto-grow
              rows="1"
              class="mt-0 pt-0 custom-prepend items-center print-hidden"
            />
          </div>
        </template>
      </v-data-table>
    </v-row>
    <v-row class="mt-0">
      <v-col class="flex" cols="7">
        <p style="min-width: 140px">Ghi chú/Tái khám: </p>
        <p class="break-words underline print-show">{{ modelNote }}</p>
        <v-textarea
          v-model="modelNote"
          hide-details
          auto-grow
          rows="1"
          class="mt-0 pt-0 custom-prepend print-hidden"
        />
      </v-col>
      <v-col cols="5">
        <div class="flex justify-between">
          <span>Tổng giá sản phẩm: </span>
          <span>xxxxxđ</span>
        </div>
        <div class="flex justify-between">
          <span>Phí vận chuyển: </span>
          <span>xxxxxđ</span>
        </div>
        <div class="flex justify-between">
          <span>Giảm giá: </span>
          <span>xxxxxđ</span>
        </div>
        <div class="flex justify-between">
          <span>Tổng số tiền: </span>
          <span>xxxxxđ</span>
        </div>
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col>
        <p class="italic">Trân trọng cảm ơn Quý khách đã tin tưởng và sử dụng sản phẩm, dịch vụ của Bảo Đại Đường! </p>
        <div class="italic">
          <span class="font-bold">Lưu ý: </span><span>Quý khách cần tư vấn thêm hoặc khiếu nại về sản phẩm vui lòng gọi điện tới  
số Hotline của Phòng khám để được hỗ trợ. </span>
        </div>
      </v-col>
      <v-col>
        <p class="text-center mb-0">Ngày <span>xx</span> tháng <span>xx</span> năm <span>xxxx</span></p>
        <p class="text-center font-bold">Bác sĩ</p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { required } from "~/utils/validation";

export default {
  name: "PhieuThanhToan",
  data() {
    return {
      isInputPhone: false,
      phoneModel: null,
      valid: true,
      modelNote: "",
      modelGuide: "",
      rules: {
        required,
      },
      headers: [
        { text: "MÃ SẢN PHẨM", value: "name", sortable: false, align: "left" },
        { text: "SẢN PHẨM", value: "description", sortable: false, align: "left" },
        { text: "SỐ LƯỢNG", value: "quantity", sortable: false, align: "center" },
        { text: "ĐƠN GIÁ", value: "pricePerPiece", sortable: false, align: "left" },
      ],
      items: [
        { name: "xyz", description: "xxxxxxxxx-xxxxxxxxxxx", quantity: 1, pricePerPiece: "10.000vnđ", modelGuide: "" },
        { name: "xyz", description: "xxxxxxxxx-xxxxxxxxxxx", quantity: 1, pricePerPiece: "10.000vnđ", modelGuide: "" },
      ],
    };
  },
  methods: {
    print() {
      window.print();
    },
  },
};
</script>

<style type="sass" media="print" scoped>
@page {
  size: auto;
  margin: 0;
}

.print-show {
  display: none;
}

.print-hidden {
  display: block;
}

@media print {
  .print-show {
    display: block;
  }

  .print-hidden {
    display: none;
  }

  .button-print {
    display: none;
  }
}

p {
  margin-bottom: 4px;
  color: black !important;
  font-family: "SanFrancisco-Bold";
}

span {
  color: black !important;
  font-family: "SanFrancisco" !important;
}

.text-small {
  font-size: 14px;
}

.text-medium {
  font-size: 16px;
}

.text-bold {
  font-weight: bold;
}

.title {
  color: black !important;
  font-family: "SanFrancisco-Bold" !important;
  font-size: 30px !important;
}

.brand {
  color: black !important;
  font-family: "Mabella" !important;
  font-size: 30px !important;
}

.table-data {
  width: 100% !important;
  color: black !important;
}

.qr-code {
  width: 60px !important;
  height: 60px !important;
}
</style>
